.treebox /deep/ .el-dialog__body {
  padding: 30px 20px 0 30px;
}
.treebox /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #5c6be8;
  color: #fff;
}
.treebox /deep/ .el-tree-node__content {
  padding-right: 0.625rem;
}
.treebox .tree .el-input__inner {
  border-color: #dcdfe6 !important;
  width: 100% !important;
}
