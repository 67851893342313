




















































































































































































































































































































































































































































































































































































































































































.treebox {
  // .el-dialog__header {
  //   background-color: #1b2e4b;
  //   text-align: center;
  //   .el-dialog__title {
  //     color: #fff;
  //   }
  //   .el-dialog__headerbtn {
  //     top: 20px;
  //   }
  //   .el-dialog__headerbtn .el-dialog__close {
  //     color: #fff;
  //     font-size: 1.2rem;
  //   }
  //   .el-icon-close:before {
  //     border: 1px solid #fff;
  //     border-radius: 50%;
  //   }
  // }
  /deep/.el-dialog__body {
    padding:30px 20px 0 30px;
  }
 /deep/ .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: #5c6be8;
    color: #fff;
  }
  /deep/.el-tree-node__content {
    padding-right: 0.625rem;
  }
  .tree {
    .el-input__inner {
      border-color: #dcdfe6 !important;
      width: 100% !important;
    }
  }
}
